import React from 'react';
import { SignupForm } from '../components/utils/form';

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => {
    
    return (<Layout>
        <SEO title="Contact" />
        
        <div className="row main-content container" id="mainContent">
            
            <div className="row main-content__box">
            <h2>CONTACT</h2>

            <p>Feel free to contact me using the form below, messaging me on <a href="https://www.linkedin.com/in/katmetax/" target="_blank" rel="noopener noreferrer">LinkedIn</a> or by emailing me directly at <a href="mailto:kat@katmetax.com">kat@katmetax.com</a>.</p>
            {/* <p>You can also find me on:</p>
            <ul>
            <li>LinkedIn at <a href="https://www.linkedin.com/in/katmetax/" target="_blank">linkedin.com/katmetax</a></li>
            <li>Github at <a href="https://github.com/katmetax" target="_blank">github.com/katmetax</a></li>
            </ul> */}

            <SignupForm/>
            </div>
            </div>
        
        </Layout>)
    
}

export default ContactPage