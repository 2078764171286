import React, { useState } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';

export const SignupForm = () => {
  
  let [ status, setStatus ] = useState("");
  
  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      email: '',
      formMessage: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .required('Required'),
      phone: Yup.number()
      .min(7, 'Please supply a valid phone number'),
      email: Yup.string()
      .email('Invalid email address')
      .required('Required'),
      formMessage: Yup.string()
      .min(15, 'Must be 15 characters or more')
      .required('Required'),
    }),
    onSubmit: (values, FormikBag) => {
      const data = new FormData();
      const xhr = new XMLHttpRequest();
      
      for ( var key in values ) {
        data.append(key, values[key]);
      }
      
      xhr.open('POST', 'https://formspree.io/xeqerrak');
      xhr.setRequestHeader("Accept", "application/json");
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) return;
        if (xhr.status === 200) {
          FormikBag.resetForm(true);
          setStatus("SUCCESS");
        } else {
          setStatus("ERROR");
        }
      };
      xhr.send(data);
    },
  });
  
  if ( status === "SUCCESS" ) {
    return (
      <div>
      <h5>Message received!</h5>
      <p>Thank you for getting in touch! I will get back to you as soon as possible!</p>
      </div>
      )
    } else {
      return (
        <form onSubmit={formik.handleSubmit} className="contact-form">
        
        <div className="contact-form__form-group columns six">
        
        <label htmlFor="Name">Name</label>
        <input label="Name" id="name" name="name" className="contact-form__form-group__input" type="text" 
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.name} />
        {formik.touched.name && formik.errors.name ? (
          <div className="validation-error">{formik.errors.name}</div>
          ) : null}
          
          </div>
          
          
          <div className="contact-form__form-group columns six">
          
          <label htmlFor="Phone">Phone</label>
          <input label="Phone" id="phone" name="phone" className="contact-form__form-group__input" type="tel" 
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.phone} />
          {formik.touched.phone && formik.errors.phone ? (
            <div className="validation-error">{formik.errors.phone}</div>
            ) : null}
            
            </div>
            
            
            <div className="contact-form__form-group columns twelve">
            
            <label htmlFor="Email">Email Address</label>
            <input label="Email" id="email" name="email" className="contact-form__form-group__input" type="email" 
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email} />
            {formik.touched.email && formik.errors.email ? (
              <div className="validation-error">{formik.errors.email}</div>
              ) : null}
              
              </div>
              
              
              <div className="contact-form__form-group columns twelve">
              
              <label htmlFor="Message">Message</label>
              <textarea label="Message" id="message" name="formMessage" className="contact-form__form-group__input message" type="text" 
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.formMessage}></textarea>
              {formik.touched.formMessage && formik.errors.formMessage ? (
                <div className="validation-error">{formik.errors.formMessage}</div>
                ) : null}
                
                </div>
                
                <div className="twelve columns">
                <button className="contact-form-btn" type="submit">SEND</button>
                {status === "ERROR" && <p>Oops! There was an error.</p>}
                </div>
                
                </form>
                );
              }
            };